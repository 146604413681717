<template lang="pug">
    
    .w-full.orcamento-agendamento

        .absolute.top-0.left-0.p-2
            a.text-3xl(@click='back()')
                i.jam.jam-chevron-left

        b-loading(v-if='waiting.refresh' :is-full-page='true' v-model='waiting.refresh')

        div

            .my-4.p-2(v-if='! agendamentoCriado')

                p.font-bold.text-sm.text-center.text-gray-600 Agendamento

                .my-4.bg-gray-100.p-2.border

                    .flex.items-center.mb-2.p-2

                        .text-center(v-if='$parent.agendamento.estabelecimento.aq_foto')
                            .w-12.h-12.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat(
                                :style="`background-image: url('${ $parent.agendamento.estabelecimento.aq_foto }')`")

                        .px-3(class='w-10/12')
                            p.text-sm.font-bold.text-medclub-blue-400 {{ $parent.agendamento.estabelecimento.nm_estabelecimento }}
                            p.text-xs.text-medclub-blue-300.my-1
                                span {{ $parent.agendamento.estabelecimento.nm_rua }}
                                // span , {{ $parent.agendamento.estabelecimento.nr_estabelecimento }}
                                span &nbsp;- {{ $parent.agendamento.estabelecimento.nm_bairro }}
                                span(v-if='$parent.agendamento.estabelecimento.ds_complemento') &nbsp;- {{ $parent.agendamento.estabelecimento.ds_complemento }}
                            p.text-xs.text-medclub-blue-300(v-if='$parent.agendamento.estabelecimento.nr_telefone') {{ $root.applyTelefoneMask($parent.agendamento.estabelecimento.nr_telefone) }}

                    p.font-bold.text-sm.text-center.text-gray-600 Selecione a(s) data(s)

                    div(v-if='Object.keys($parent.agendamento.calendarioLaboratoriais).length')

                        .mt-2(v-if='Object.keys($parent.agendamento.calendarioLaboratoriais).length')
                            label.mt-4.px-2.font-bold.text-sm.text-gray-700 Exames Laboratoriais ({{ $root.formatPrice(sumLaboratoriais()) }}):
                            .p-2.my-1
                                .border.border-blue-200.bg-blue-100.p-2
                                    p.text-xs(v-for='exame in $parent.agendamento.examesLaboratoriais') • {{ exame.ds_procedimento }} - {{ $root.formatPrice(exame.nr_valor) }}
                            .px-2
                                b-datepicker(v-model='dataLaboratoriais' :selectable-dates='calendarioLaboratoriais' placeholder='Selecione a data' :locale="'pt-BR'" icon='calendar-today' trap-focus @input='getCodigoLaboratoriais()')

                    .my-8(v-for='exameId in Object.keys(calendarioImagem)')
                        .flex.flex-wrap.items-end(v-if='calendarioImagem[exameId].length')
                            .w-full.px-2.mb-1
                                label.font-bold.text-sm.text-gray-700 {{ $parent.agendamento.examesImagem.find(e => e.id == exameId).ds_procedimento }} ({{ $root.formatPrice($parent.agendamento.examesImagem.find(e => e.id == exameId).nr_valor) }}):
                            
                            .p-2.w-full(v-if='! dataImagem[exameId].selected')
                                b-datepicker(v-model='dataImagem[exameId].data' :selectable-dates='calendarioImagem[exameId]' :locale="'pt-BR'" icon='calendar-today' placeholder='Selecione a data' trap-focus @input='getHorarios(exameId)')
                                
                            .p-1.w-full.flex.flex-wrap(v-else)

                                .p-1.text-xs.bg-yellow-200.p-2.border.border-yellow-300.w-full.mb-1(v-if='dataImagem[exameId].selected.ie_agenda_presencial') <b>Atenção!</b> É necessário agendar com a clínica.
                                
                                .p-1(class='w-full md:w-1/4')
                                    b-datepicker.w-full(
                                        v-model='dataImagem[exameId].data'
                                        :selectable-dates='calendarioImagem[exameId]'
                                        :locale="'pt-BR'"
                                        icon='calendar-today'
                                        placeholder='Selecione a data'
                                        trap-focus
                                        @input='getHorarios(exameId)')
                                .p-1(class='w-full md:w-1/4' v-if='dataImagem[exameId].selected.ie_hora_marcada')
                                    b-select.text-sm(v-model='dataImagem[exameId].selected.horario' placeholder='Selecione um horário' icon='calendar-clock' :expanded='true')
                                        option.text-sm(v-for='option in dataImagem[exameId].selected.hr_horario' :key='option.id' :value='option.id') {{ option.hr_agenda }}
                                .p-1(class='w-full md:w-1/4' v-if='dataImagem[exameId].selected.ds_orientacao')
                                    b-button.w-full.text-sm(type='is-info' icon-pack='jam' icon-left='jam-document' @click="alert('Ver preparo', dataImagem[exameId].selected.ds_procedimento, dataImagem[exameId].selected.ds_orientacao)") Ver preparo
                                .p-1(class='w-full md:w-1/4' v-if='dataImagem[exameId].selected.ds_observacao')
                                    b-button.w-full.text-sm(type='is-info' icon-pack='jam' icon-left='jam-document' @click="alert('Ver observações', dataImagem[exameId].selected.ds_procedimento, dataImagem[exameId].selected.ds_observacao)") Ver observações

                        .px-2(v-else)
                            p.text-sm.text-red-700
                                span.line-through {{ $parent.agendamento.examesImagem.find(e => e.id == exameId).ds_procedimento }}
                                span.ml-2 (Sem data disponível)

                    .flex.items-center.p-4.mx-auto.border.bg-white.mt-4(class='md:w-1/2')
                        .flex.flex-wrap.justify-center(class='w-1/2')
                            p.text-xs.text-gray-600.w-full.text-center VALOR TOTAL
                            p.font-bold.text-xl.text-gray-700.w-full.text-center {{ $root.formatPrice(sumTotal()) }}
                        .flex.justify-center(class='w-1/2')
                            b-button.text-sm(@click='agendar()' type='is-primary' icon-pack='jam' icon-left='jam-check') Agendar

            div(v-else)
                SelecionarPaciente

</template>

<style lang="scss">
    .orcamento-agendamento {
        .field .label {
            font-size: 0.875rem;
        }
        .datepicker input {
            font-size: 0.875rem;
        }
    }
</style>

<script>
    import SelecionarPaciente from './../Agendamento/Etapas/Paciente'
    import moment from 'moment'
    import { Agendamento } from '../../middleware'
    export default {
        components: { SelecionarPaciente },
        data () {

            let calendarioLaboratoriais = Object.keys(this.$parent.agendamento.calendarioLaboratoriais).map(d => moment(d, 'DD/MM/YYYY', true)._d)
            let calendarioImagem = {}
            let dataImagem = {}

            Object.keys(this.$parent.agendamento.calendarioImagem).forEach(exameId => {
                calendarioImagem[exameId] = Object.keys(this.$parent.agendamento.calendarioImagem[exameId]).map(d => moment(d, 'DD/MM/YYYY', true)._d)
                if (Object.keys(this.$parent.agendamento.calendarioImagem[exameId]).length) dataImagem[exameId] = { data: null, horario: null, selected: null }
            })
            
            return {
                dataLaboratoriais: null,
                dataImagem,
                calendarioLaboratoriais,
                calendarioImagem,
                codigosLaboratoriais: [],
                agendamento: {
                    ie_tipo_agenda: 'VE',
                    procedimentos: [],
                    estabelecimento: null,
                    paciente: null
                },
                agendamentoCriado: false,
                waiting: { refresh: false },
                sumLaboratoriais () {
                    return this.$parent.agendamento.examesLaboratoriais.reduce((ac, cur) => ac + cur.nr_valor, 0)
                },
                sumTotal () {
                    return this.$parent.agendamento.examesImagem
                        .filter(e => calendarioImagem[e.id].length > 0)
                        .reduce((ac, cur) => ac + cur.nr_valor, 0) + this.sumLaboratoriais()
                }
            }
        },
        methods: {
            back () {
                this.$parent.agendamento = {
                    examesLaboratoriais: [],
                    examesImagem: [],
                    calendarioLaboratoriais: [],
                    calendarioImagem: {},
                    estabelecimento: null,
                    agendar: false
                }
            },
            alert (title, subtitle, text) {

                const helpButtonMobile = document.getElementById('helpButtonMobile')
                if (helpButtonMobile) helpButtonMobile.classList.add('hidden')

                this.$buefy.dialog.alert({
                    title,
                    message: `<div class='mb-4'><h1 class='font-bold text-lg'>${ subtitle }</h1></div><div class='whitespace-pre-wrap text-sm'>${ text }</div>`,
                    confirmText: 'Entendi',
                    onConfirm: () => {
                        const helpButtonMobile = document.getElementById('helpButtonMobile')
                        if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')
                    }
                })
            },
            getCodigoLaboratoriais () {
                this.codigosLaboratoriais = []
                if (this.dataLaboratoriais) {
                    this.waiting.refresh = true
                    let dataSend = {
                        dt_agenda: moment(this.dataLaboratoriais).format('YYYY-MM-DD'),
                        cd_procedimento: this.$parent.agendamento.examesLaboratoriais.map(e => e.id).toString(),
                        cd_cidade: this.$store.state.cdCidade,
                        cd_estado: this.$store.state.cdEstado,
                        ie_atende_medclub_empresa: localStorage.getItem("MEDCLUB_TYPE_USER") === "true",
                    }
                    Agendamento.getEstabelecimentosProcedimentosCalendario(dataSend).then(response => {
                        if (response.status === 200) {
                            let estabelecimento = response.data.find(e => e.cd_estabelecimento === this.$parent.agendamento.estabelecimento.cd_estabelecimento)
                            this.codigosLaboratoriais = {
                                idHorario: estabelecimento.horarios_reservar[0],
                                cd_procedimento: estabelecimento.procedimentos.map(p => p.id)
                            }
                        }
                        this.waiting.refresh = false
                    })
                }
            },
            getHorarios (exameId) {
                this.waiting.refresh = true
                window.setTimeout(() => {
                    this.waiting.refresh = false
                    this.dataImagem[exameId].selected = this.$parent.agendamento.calendarioImagem[exameId][moment(this.dataImagem[exameId].data).format('DD/MM/YYYY')]
                }, 250)
            },
            agendar () {

                if (Object.keys(this.$parent.agendamento.calendarioLaboratoriais).length && ! this.dataLaboratoriais) {
                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Preencha todas as datas para continuar' })
                    return 0
                }

                if (this.$parent.agendamento.examesImagem.length) {
                    let filter = Object.values(this.dataImagem).filter(item => ! item.data)
                    if (filter.length) {
                        this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Preencha todas as datas para continuar' })
                        return 0
                    }
                    
                    filter = Object.values(this.dataImagem).filter(item => item.selected.ie_hora_marcada && ! item.selected.horario)
                    if (filter.length) {
                        this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Preencha todos os horários para continuar' })
                        return 0
                    }
                }

                this.$buefy.dialog.confirm({
                    message: `<p>Deseja realizar o agendamento?</p>`,
                    confirmText: 'Agendar',
                    cancelText: 'Cancelar',
                    onConfirm: () => {

                        let checked = true
                        
                        if (Object.keys(this.$parent.agendamento.calendarioLaboratoriais).length)
                            this.agendamento.procedimentos = [ this.codigosLaboratoriais ]

                        if (this.$parent.agendamento.examesImagem.length) {

                            let mem = {}
                            this.$parent.agendamento.examesImagem.map(e => e.id).forEach(exameId => {
                                if (this.dataImagem[exameId])
                                    if (this.dataImagem[exameId].data && checked) {
                                        
                                        let estabelecimento = this.$parent.agendamento.calendarioImagem[exameId][moment(this.dataImagem[exameId].data).format('DD/MM/YYYY')]
                                        
                                        if (this.dataImagem[exameId].horario) {
                                            let idHorario = this.dataImagem[exameId].horario
                                            mem[idHorario] = true
                                            this.agendamento.procedimentos.push({
                                                idHorario, cd_procedimento: [ estabelecimento.cd_procedimento_estabelecimento ]
                                            })
                                        } else {

                                            let idHorario = 0
                                            estabelecimento.hr_horario.forEach(hr => {
                                                if (! idHorario && ! mem[hr.id]) {
                                                    idHorario = hr.id
                                                    mem[idHorario] = true
                                                }
                                            })

                                            if (idHorario)
                                                this.agendamento.procedimentos.push({
                                                    idHorario, cd_procedimento: [ estabelecimento.cd_procedimento_estabelecimento ]
                                                })
                                            else checked = false

                                        }
                                        
                                    }
                            })

                        }

                        if (checked) {
                            this.agendamento.estabelecimento = this.$parent.agendamento.estabelecimento
                            this.agendamentoCriado = true
                        } else this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Dias com choque de horário, selecione algum diferente' })

                    }
                })
            }
        }
    }
</script>