<template lang="pug">
.flex.items-center.justify-center.flex-wrap
        .max-w-screen-lg.w-full.relative(style='min-height:75vh')

            div(v-if='verResultado')
                ResultadoComponent

            div(v-else)

                .absolute.top-0.left-0.p-2
                    a.text-3xl(v-if='! filter.nm_procedimento &&  ! $route.params.ids' @click='$router.go(-1); test()')
                        i.jam.jam-chevron-left

                .w-full
                    .pt-4.pb-3.text-center.w-full
                        h1.font-bold.text-medclub-blue-300.text-sm Ver preços

                    .p-1.relative(v-if='! mostrarSelecionados && ! $route.params.ids')
                        b-input(id='search-precos' placeholder='Pesquisar' icon='magnify' v-model='filter.nm_procedimento' @input='getOrcamento()')
                        .absolute.top-0.right-0.p-2
                            .cursor-pointer.py-1.px-2.bg-opacity-75.rounded-full.flex.items-center.bg-medclub-blue-300.text-white.t-0.r-0(v-if='filter.nm_procedimento' @click='limpar()')
                                b-icon(pack='jam' icon='jam-trash')
                                span.text-xs Limpar


                    .p-3(v-if='list.length')
                        .w-full.flex.items-center
                            b-switch(v-model='mostrarSelecionados')
                                p.text-xs Mostrar apenas os itens selecionados

                    .py-56(v-if='waiting.exames')
                        b-notification.p-8.bg-white(:closable='false')
                            b-loading(:is-full-page='false' v-model='waiting.exames')

                    div(v-else)

                        .flex.flex-wrap.pb-4
                        
                            .p-1.w-full.text-center(v-if='! list.length')
                                p.text-sm.my-6 Nenhum item {{ mostrarSelecionados ? 'selecionado' : 'encontrado' }}.

                            .p-1.w-full(v-else-if='mostrarSelecionados')
                                div(v-if='selected.length')
                                    .w-full.shadow-md.border-b(v-for='exame in selected' :key='exame.id')
                                        .flex.items-center.bg-white.border.border-b-0.cursor-pointer(@click='select(exame.id, $event)')
                                            .wrapper.p-2.w-full.flex.items-center
                                                b-checkbox(v-model='selected' :id="`selected${ exame.id }`" :native-value='exame')
                                                    span.text-sm.text-gray-700 {{ exame.nm_procedimento }}

                                .p-2(v-else)
                                    p.text-sm.text-center.text-gray-600 Você ainda não selecionou nenhum item.

                            .p-1.w-full(v-else)
                                .w-full.shadow-md.border-b(v-for='exame in list' :key='exame.id')
                                    .flex.items-center.bg-white.border.border-b-0.cursor-pointer(class='hover:bg-gray-100' @click='select(exame.id, $event)')
                                        .wrapper.p-2.w-full.flex.items-center
                                            b-checkbox.b-checkbox-exames.w-full(v-model='selected' :id="`selected${ exame.id }`" :native-value='exame')
                                                .w-full.flex.justify-between.items-center
                                                    .w-full
                                                        p.font-bold.w-full(class='w-9/12') {{ exame.nm_procedimento }}<br/>
                                                        span.text-xs(v-if="exame.ds_sinonimia") Sinônimos: 
                                                            span.mt-1.w-full.text-xs(v-if="exame.ds_sinonimia") {{ exame.ds_sinonimia }}
                                                        p.text-xs.mt-2.font-bold.w-full
                                                            span a partir de
                                                            span.text-black-700(style='font-size: 0.95em; color:#3C60BA' ) &nbsp;{{ $root.formatPrice(exame.nr_valor) }}

                            .py-2.w-full.flex.justify-center(v-if='waiting.nextPage')
                                b-notification.p-8.bg-white(:closable='false')
                                    b-loading.z-0(:is-full-page='false' v-model='waiting.nextPage')

                            .w-full.bottom-0.left-0.right-0.bg-white.p-4.text-center.sticky(v-if='selected.length' style='z-index:1')
                                b-button.w-full.rounded.p-2.h-auto.relative(style='z-index:99' type='is-primary' class='md:max-w-xs' @click='concluir()')
                                    .flex.items-center
                                        .text-sm CONTINUAR
                                        .ml-2.w-8.h-8.leading-8.rounded-full.bg-red-700.text-xs.text-center {{ selected.length }}



</template>

<script>
    import { Utils } from '../../middleware'
    import ResultadoComponent from './Resultado'
    import crypto from 'crypto'

    export default {
        created () {
            if (this.$route.params.ids) {
                let hash = this.decrypt(this.$route.params.ids)
                if (! hash) this.$router.push({ name: 'home' })
                else if (this.validateHash(hash)) {
                    Utils.getOrcamento({ cd_cidade: this.$store.state.cdCidade }).then(response => {
                        this.waiting.exames = false
                        if (response.status === 200) {
                            let selected = hash.split('&').map(val => parseInt(val))
                            this.selected = response.data.filter(e => selected.includes(e.id))
                            this.verResultado = true
                        }
                    })
                } else this.getOrcamento()
            } else this.getOrcamento()
        },
        components: { ResultadoComponent },
        // watch: {
        //     'verResultado': function(val) {
        //         //if (! val && this.$route.params.ids) this.$router.push({ name: 'orcamento' })
        //         console.log(val)
        //     }
        // },
        mounted () {
            window.onscroll = () => {
                if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 200) {
                    if (! this.waiting.nextPage && ! this.verResultado && ! this.filter.nm_procedimento) {
                        this.page++
                        this.getOrcamento()
                    }
                }
            }
        },
        data () {
            return {
                list: [],
                selected: [],
                lockPage: false,
                mostrarSelecionados: false,
                waiting: { exames: true, nextPage: false },
                filter: { nm_procedimento: null },
                page: 1,
                verResultado: false,
                cryptoConfigs: {
                    algorithm: 'aes-192-cbc',
                    password: 'm3dc1u6'
                }
            }
        },
        methods: {
            test () {
                console.log(this.$route.params.ids)
            },
            getOrcamento () {
                if (this.$route.params.ids) { this.$router.push({ name: 'orcamento' }) }
                if (this.mostrarSelecionados) return 0
                if (! this.filter.nm_procedimento && this.lockPage) return 0
                if (this.page === 1 && ! this.filter.nm_procedimento) this.waiting.exames = true
                else this.waiting.nextPage = true

                let params = { page: this.page }

                if (this.filter.nm_procedimento) {
                    params.nm_procedimento = this.filter.nm_procedimento
                    params.page = 1
                    this.page = 1
                    this.lockPage = false
                }

                params.cd_cidade = this.$store.state.cdCidade

                Utils.getOrcamento(params).then(response => {
                    this.waiting.exames = false
                    this.waiting.nextPage = false
                    if (response.status === 200) {
                        if (response.data.length || this.filter.nm_procedimento) {
                            if (this.page === 1) this.list = response.data
                            else this.list = this.list.concat(response.data)
                        } else {
                            this.lockPage = true
                        }  
                    }
                })
            },
            select (id, ev) {
                if (ev.target.classList.contains('wrapper')) {
                    let el = document.getElementById(`selected${ id }`)
                    el.click()
                }
            },
            concluir () {
                let ids = this.selected.map(e => e.id).join('&')
                ids = this.encrypt(ids)
                this.$router.push({ name: 'orcamentoCompartilhar', params: { ids } })
                this.verResultado = true
            },
            encrypt (text) {
                let configs = this.cryptoConfigs
                let cipher = crypto.createCipher(configs.algorithm, configs.password)
                let crypted = cipher.update(text,'utf8','hex')
                crypted += cipher.final('hex')
                return crypted
            },
            decrypt (text) {
                let configs = this.cryptoConfigs
                try {
                    let decipher = crypto.createDecipher(configs.algorithm, configs.password)
                    let dec = decipher.update(text,'hex','utf8')
                    dec += decipher.final('utf8')
                    return dec
                } catch (err) {
                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Link inválido' })
                    return false
                }
            },
            validateHash (hash) {
                return /(\d*&)*\d+$/g.test(hash)
            },
            limpar() {
                this.filter.nm_procedimento = null
                var searchField = document.getElementById("search-precos");
                searchField.focus()
                this.getOrcamento()

            }
        }
    }
</script>